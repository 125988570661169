import { Record } from "../fable-library.3.7.12/Types.js";
import { enum_type, int32_type, record_type, string_type } from "../fable-library.3.7.12/Reflection.js";

export class Months extends Record {
    constructor(January, February, March, April, May, June, July, August, September, October, November, December) {
        super();
        this.January = January;
        this.February = February;
        this.March = March;
        this.April = April;
        this.May = May;
        this.June = June;
        this.July = July;
        this.August = August;
        this.September = September;
        this.October = October;
        this.November = November;
        this.December = December;
    }
}

export function Months$reflection() {
    return record_type("Date.Local.Months", [], Months, () => [["January", string_type], ["February", string_type], ["March", string_type], ["April", string_type], ["May", string_type], ["June", string_type], ["July", string_type], ["August", string_type], ["September", string_type], ["October", string_type], ["November", string_type], ["December", string_type]]);
}

export class DaysOfWeek extends Record {
    constructor(Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday) {
        super();
        this.Monday = Monday;
        this.Tuesday = Tuesday;
        this.Wednesday = Wednesday;
        this.Thursday = Thursday;
        this.Friday = Friday;
        this.Saturday = Saturday;
        this.Sunday = Sunday;
    }
}

export function DaysOfWeek$reflection() {
    return record_type("Date.Local.DaysOfWeek", [], DaysOfWeek, () => [["Monday", string_type], ["Tuesday", string_type], ["Wednesday", string_type], ["Thursday", string_type], ["Friday", string_type], ["Saturday", string_type], ["Sunday", string_type]]);
}

export class Date$ extends Record {
    constructor(Months, AbbreviatedMonths, Days, AbbreviatedDays, DefaultFormat, FirstDayOfTheWeek) {
        super();
        this.Months = Months;
        this.AbbreviatedMonths = AbbreviatedMonths;
        this.Days = Days;
        this.AbbreviatedDays = AbbreviatedDays;
        this.DefaultFormat = DefaultFormat;
        this.FirstDayOfTheWeek = (FirstDayOfTheWeek | 0);
    }
}

export function Date$$reflection() {
    return record_type("Date.Local.Date", [], Date$, () => [["Months", Months$reflection()], ["AbbreviatedMonths", Months$reflection()], ["Days", DaysOfWeek$reflection()], ["AbbreviatedDays", DaysOfWeek$reflection()], ["DefaultFormat", string_type], ["FirstDayOfTheWeek", enum_type("System.DayOfWeek", int32_type, [["Sunday", 0], ["Monday", 1], ["Tuesday", 2], ["Wednesday", 3], ["Thursday", 4], ["Friday", 5], ["Saturday", 6]])]]);
}

export class Time extends Record {
    constructor(AM, PM) {
        super();
        this.AM = AM;
        this.PM = PM;
    }
}

export function Time$reflection() {
    return record_type("Date.Local.Time", [], Time, () => [["AM", string_type], ["PM", string_type]]);
}

export class Localization extends Record {
    constructor(Date$, Time) {
        super();
        this.Date = Date$;
        this.Time = Time;
    }
}

export function Localization$reflection() {
    return record_type("Date.Local.Localization", [], Localization, () => [["Date", Date$$reflection()], ["Time", Time$reflection()]]);
}

export const englishUK = new Localization(new Date$(new Months("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"), new Months("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"), new DaysOfWeek("Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"), new DaysOfWeek("Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"), "d/M/yyyy", 1), new Time("AM", "PM"));

export const englishUS = new Localization(new Date$(new Months("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"), new Months("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"), new DaysOfWeek("Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"), new DaysOfWeek("Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"), "M/d/yyyy", 0), new Time("AM", "PM"));

export const french = new Localization(new Date$(new Months("Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"), new Months("Jan", "Fév", "Mars", "Avr", "Mai", "Jui", "Juil", "Août", "Sep", "Oct", "Nov", "Dec"), new DaysOfWeek("Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"), new DaysOfWeek("Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"), "d/M/yyyy", 1), new Time("", ""));

export const russian = new Localization(new Date$(new Months("Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"), new Months("Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"), new DaysOfWeek("Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"), new DaysOfWeek("Пон", "Втр", "Срд", "Чтв", "Птн", "Сбт", "Вск"), "d.M.yyyy", 1), new Time("", ""));

export const hungarian = new Localization(new Date$(new Months("Január", "Február", "Március", "Április", "Május", "Június", "Július", "Augusztus", "Szeptember", "Október", "November", "December"), new Months("Jan", "Feb", "Márc", "Ápr", "Máj", "Jún", "Júl", "Aug", "Szept", "Okt", "Nov", "Dec"), new DaysOfWeek("Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat", "Vasárnap"), new DaysOfWeek("Hét", "Kedd", "Sze", "Csüt", "Pén", "Szo", "Vas"), "yyyy.MM.dd.", 1), new Time("de", "du"));

export const german = new Localization(new Date$(new Months("Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"), new Months("Jan", "Feb", "März", "Apr", "Mai", "Juni", "Juli", "Aug", "Sept", "Okt", "Nov", "Dez"), new DaysOfWeek("Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"), new DaysOfWeek("Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"), "dd.MM.yyyy", 1), new Time("", ""));

