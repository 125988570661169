import { singleton } from "../fable-library.3.7.12/AsyncBuilder.js";
import { Result_Map, FSharpResult$2, Result_MapError } from "../fable-library.3.7.12/Choice.js";
import { zipError as zipError_1, zip as zip_1, teeErrorIf as teeErrorIf_1, teeError as teeError_1, teeIf as teeIf_1, tee as tee_1, defaultWith as defaultWith_1, defaultError as defaultError_1, defaultValue as defaultValue_1, withError as withError_1, setError as setError_1, requireHead as requireHead_1, requireNotEmpty as requireNotEmpty_1, requireEmpty as requireEmpty_1, requireEqualTo as requireEqualTo_1, requireEqual as requireEqual_1, requireNone as requireNone_1, requireSome as requireSome_1, requireFalse as requireFalse_1, requireTrue as requireTrue_1, map3 as map3_1, map2 as map2_1, fold, either } from "./Result.fs.js";
import { Async_zip, Async_map3, Async_map2 } from "./Async.fs.js";
import { catchAsync } from "../fable-library.3.7.12/Async.js";

export function mapError(f, ar) {
    return singleton.Bind(ar, (arg) => {
        const value = Result_MapError(f, arg);
        return singleton.Return(value);
    });
}

export function bind(f, ar) {
    return singleton.Bind(ar, (x) => either(f, (arg) => singleton.Return(new FSharpResult$2(1, arg)), x));
}

export function foldResult(onSuccess, onError, ar) {
    return singleton.Bind(ar, (arg) => {
        const value = fold(onSuccess, onError, arg);
        return singleton.Return(value);
    });
}

export function retn(x) {
    return singleton.Return(new FSharpResult$2(0, x));
}

export function ok() {
    return retn;
}

export function returnError(x) {
    return singleton.Return(new FSharpResult$2(1, x));
}

export function error() {
    return returnError;
}

export function map2(f, xR, yR) {
    return Async_map2((x, y) => map2_1(f, x, y), xR, yR);
}

export function map3(f, xR, yR, zR) {
    return Async_map3((x, y, z) => map3_1(f, x, y, z), xR, yR, zR);
}

export function apply(fAR, xAR) {
    return map2((f, x) => f(x), fAR, xAR);
}

export function ignore(ar) {
    return singleton.Bind(ar, (arg) => {
        const value_1 = Result_Map((value) => {
        }, arg);
        return singleton.Return(value_1);
    });
}

export function requireTrue(error_1, value) {
    return singleton.Bind(value, (arg) => {
        const value_2 = requireTrue_1(error_1, arg);
        return singleton.Return(value_2);
    });
}

export function requireFalse(error_1, value) {
    return singleton.Bind(value, (arg) => {
        const value_2 = requireFalse_1(error_1, arg);
        return singleton.Return(value_2);
    });
}

export function requireSome(error_1, option) {
    return singleton.Bind(option, (arg) => {
        const value = requireSome_1(error_1, arg);
        return singleton.Return(value);
    });
}

export function requireNone(error_1, option) {
    return singleton.Bind(option, (arg) => {
        const value = requireNone_1(error_1, arg);
        return singleton.Return(value);
    });
}

export function requireEqual(x1, x2, error_1) {
    return singleton.Bind(x2, (arg) => {
        const value = requireEqual_1(x1, arg, error_1);
        return singleton.Return(value);
    });
}

export function requireEqualTo(other, error_1, this$) {
    return singleton.Bind(this$, (arg) => {
        const value = requireEqualTo_1(other, error_1, arg);
        return singleton.Return(value);
    });
}

export function requireEmpty(error_1, xs) {
    return singleton.Bind(xs, (arg) => {
        const value = requireEmpty_1(error_1, arg);
        return singleton.Return(value);
    });
}

export function requireNotEmpty(error_1, xs) {
    return singleton.Bind(xs, (arg) => {
        const value = requireNotEmpty_1(error_1, arg);
        return singleton.Return(value);
    });
}

export function requireHead(error_1, xs) {
    return singleton.Bind(xs, (arg) => {
        const value = requireHead_1(error_1, arg);
        return singleton.Return(value);
    });
}

export function setError(error_1, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => {
        const value = setError_1(error_1, arg);
        return singleton.Return(value);
    });
}

export function withError(error_1, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => {
        const value = withError_1(error_1, arg);
        return singleton.Return(value);
    });
}

export function defaultValue(ifError, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => {
        const value = defaultValue_1(ifError, arg);
        return singleton.Return(value);
    });
}

export function defaultError(ifOk, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => {
        const value = defaultError_1(ifOk, arg);
        return singleton.Return(value);
    });
}

export function defaultWith(ifErrorThunk, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => {
        const value = defaultWith_1(ifErrorThunk, arg);
        return singleton.Return(value);
    });
}

export function ignoreError(result) {
    return defaultValue(void 0, result);
}

export function tee(f, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => {
        const value = tee_1(f, arg);
        return singleton.Return(value);
    });
}

export function teeIf(predicate, f, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => {
        const value = teeIf_1(predicate, f, arg);
        return singleton.Return(value);
    });
}

export function teeError(f, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => {
        const value = teeError_1(f, arg);
        return singleton.Return(value);
    });
}

export function teeErrorIf(predicate, f, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => {
        const value = teeErrorIf_1(predicate, f, arg);
        return singleton.Return(value);
    });
}

export function zip(x1, x2) {
    const x = Async_zip(x1, x2);
    return singleton.Bind(x, (arg) => {
        let value;
        const tupledArg = arg;
        value = zip_1(tupledArg[0], tupledArg[1]);
        return singleton.Return(value);
    });
}

export function zipError(x1, x2) {
    const x = Async_zip(x1, x2);
    return singleton.Bind(x, (arg) => {
        let value;
        const tupledArg = arg;
        value = zipError_1(tupledArg[0], tupledArg[1]);
        return singleton.Return(value);
    });
}

export function catch$(f, x) {
    const x_1 = catchAsync(x);
    return singleton.Bind(x_1, (arg) => {
        let value;
        const _arg1 = arg;
        if (_arg1.tag === 1) {
            value = (new FSharpResult$2(1, f(_arg1.fields[0])));
        }
        else {
            const copyOfStruct = _arg1.fields[0];
            value = ((copyOfStruct.tag === 1) ? (new FSharpResult$2(1, copyOfStruct.fields[0])) : (new FSharpResult$2(0, copyOfStruct.fields[0])));
        }
        return singleton.Return(value);
    });
}

export function ofAsync(x) {
    return singleton.Bind(x, (arg) => singleton.Return(new FSharpResult$2(0, arg)));
}

export function ofResult(x) {
    return singleton.Return(x);
}

