import { class_type } from "../fable-library.3.7.12/Reflection.js";
import { ResultCE_ResultBuilder__Zero, ResultCE_result, ResultCE_ResultBuilder__Return_1505 } from "./ResultCE.fs.js";
import { singleton } from "../fable-library.3.7.12/AsyncBuilder.js";
import { FSharpResult$2 } from "../fable-library.3.7.12/Choice.js";
import { getEnumerator } from "../fable-library.3.7.12/Util.js";

export class AsyncResultCE_AsyncResultBuilder {
    constructor() {
    }
}

export function AsyncResultCE_AsyncResultBuilder$reflection() {
    return class_type("FsToolkit.ErrorHandling.AsyncResultCE.AsyncResultBuilder", void 0, AsyncResultCE_AsyncResultBuilder);
}

export function AsyncResultCE_AsyncResultBuilder_$ctor() {
    return new AsyncResultCE_AsyncResultBuilder();
}

export function AsyncResultCE_AsyncResultBuilder__Return_1505(__, value) {
    const arg00 = ResultCE_ResultBuilder__Return_1505(ResultCE_result, value);
    return singleton.Return(arg00);
}

export function AsyncResultCE_AsyncResultBuilder__Zero(__) {
    const arg00 = ResultCE_ResultBuilder__Zero(ResultCE_result);
    return singleton.Return(arg00);
}

export function AsyncResultCE_AsyncResultBuilder__Delay_Z64727ECD(__, generator) {
    return singleton.Delay(generator);
}

export function AsyncResultCE_AsyncResultBuilder__Combine_Z6CC99CA7(this$, computation1, computation2) {
    return singleton.Delay(() => singleton.Bind(computation1, (_arg1) => {
        const result = _arg1;
        return (result.tag === 1) ? singleton.Return(new FSharpResult$2(1, result.fields[0])) : singleton.ReturnFrom(computation2);
    }));
}

export function AsyncResultCE_AsyncResultBuilder__TryWith_Z83E8051(__, computation, handler) {
    return singleton.TryWith(computation, handler);
}

export function AsyncResultCE_AsyncResultBuilder__TryFinally_Z1F7894D1(__, computation, compensation) {
    return singleton.TryFinally(computation, compensation);
}

export function AsyncResultCE_AsyncResultBuilder__Using_3CCE968F(__, resource, binder) {
    return singleton.Using(resource, binder);
}

export function AsyncResultCE_AsyncResultBuilder__While_6C388640(this$, guard, computation) {
    if (!guard()) {
        return AsyncResultCE_AsyncResultBuilder__Zero(this$);
    }
    else {
        return singleton.Delay(() => singleton.Bind(computation, (_arg1) => {
            const result = _arg1;
            return (result.tag === 1) ? singleton.Return(new FSharpResult$2(1, result.fields[0])) : singleton.ReturnFrom(AsyncResultCE_AsyncResultBuilder__While_6C388640(this$, guard, computation));
        }));
    }
}

export function AsyncResultCE_AsyncResultBuilder__For_620A7CFB(this$, sequence, binder) {
    let copyOfStruct;
    return AsyncResultCE_AsyncResultBuilder__Using_3CCE968F(this$, (copyOfStruct = sequence, getEnumerator(copyOfStruct)), (enum$) => AsyncResultCE_AsyncResultBuilder__While_6C388640(this$, () => enum$["System.Collections.IEnumerator.MoveNext"](), AsyncResultCE_AsyncResultBuilder__Delay_Z64727ECD(this$, () => binder(enum$["System.Collections.Generic.IEnumerator`1.get_Current"]()))));
}

export const AsyncResultCE_asyncResult = AsyncResultCE_AsyncResultBuilder_$ctor();

