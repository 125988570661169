import { class_type } from "../fable-library.3.7.12/Reflection.js";
import { Result_Map, Result_Bind, FSharpResult$2 } from "../fable-library.3.7.12/Choice.js";
import { getEnumerator, disposeSafe } from "../fable-library.3.7.12/Util.js";
import { zip } from "./Result.fs.js";

export class ResultCE_ResultBuilder {
    constructor() {
    }
}

export function ResultCE_ResultBuilder$reflection() {
    return class_type("FsToolkit.ErrorHandling.ResultCE.ResultBuilder", void 0, ResultCE_ResultBuilder);
}

export function ResultCE_ResultBuilder_$ctor() {
    return new ResultCE_ResultBuilder();
}

export function ResultCE_ResultBuilder__Return_1505(__, value) {
    return new FSharpResult$2(0, value);
}

export function ResultCE_ResultBuilder__Zero(this$) {
    return ResultCE_ResultBuilder__Return_1505(this$);
}

export function ResultCE_ResultBuilder__Delay_Z4709C901(__, generator) {
    return generator;
}

export function ResultCE_ResultBuilder__Combine_71B5E353(this$, result, binder) {
    return Result_Bind(binder, result);
}

export function ResultCE_ResultBuilder__TryWith_54462885(this$, generator, handler) {
    try {
        return generator();
    }
    catch (e) {
        return handler(e);
    }
}

export function ResultCE_ResultBuilder__TryFinally_Z12198D77(this$, generator, compensation) {
    try {
        return generator();
    }
    finally {
        compensation();
    }
}

export function ResultCE_ResultBuilder__Using_65A9B503(this$, resource, binder) {
    return ResultCE_ResultBuilder__TryFinally_Z12198D77(this$, () => binder(resource), () => {
        if (!(resource === null)) {
            let copyOfStruct = resource;
            disposeSafe(copyOfStruct);
        }
    });
}

export function ResultCE_ResultBuilder__While_Z746783BA(this$, guard, generator) {
    if (!guard()) {
        return ResultCE_ResultBuilder__Zero(this$);
    }
    else {
        return Result_Bind(() => ResultCE_ResultBuilder__While_Z746783BA(this$, guard, generator), generator());
    }
}

export function ResultCE_ResultBuilder__For_1F49FCD7(this$, sequence, binder) {
    let copyOfStruct;
    return ResultCE_ResultBuilder__Using_65A9B503(this$, (copyOfStruct = sequence, getEnumerator(copyOfStruct)), (enum$) => ResultCE_ResultBuilder__While_Z746783BA(this$, () => enum$["System.Collections.IEnumerator.MoveNext"](), ResultCE_ResultBuilder__Delay_Z4709C901(this$, () => binder(enum$["System.Collections.Generic.IEnumerator`1.get_Current"]()))));
}

export function ResultCE_ResultBuilder__BindReturn_Z2499A5D(_, x, f) {
    return Result_Map(f, x);
}

export function ResultCE_ResultBuilder__MergeSources_Z1F053A60(_, t1, t2) {
    return zip(t1, t2);
}

export const ResultCE_result = ResultCE_ResultBuilder_$ctor();

