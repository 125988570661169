import { getCharAtIndex, substring, padLeft, printf, toFail } from "../fable-library.3.7.12/String.js";
import { int32ToString, comparePrimitives, max } from "../fable-library.3.7.12/Util.js";
import { replace } from "../fable-library.3.7.12/RegExp.js";
import { year as year_1, second, month as month_2, minute, hour, dayOfWeek, day as day_2 } from "../fable-library.3.7.12/Date.js";

export function fromDayOfWeek(local, day) {
    switch (day) {
        case 0: {
            return local.Sunday;
        }
        case 1: {
            return local.Monday;
        }
        case 2: {
            return local.Tuesday;
        }
        case 3: {
            return local.Wednesday;
        }
        case 4: {
            return local.Thursday;
        }
        case 5: {
            return local.Friday;
        }
        case 6: {
            return local.Saturday;
        }
        default: {
            return toFail(printf("Not a valid day of week: %A"))(day);
        }
    }
}

export function mod12(x) {
    if ((x === 12) ? true : (x === 0)) {
        return 12;
    }
    else {
        return (x % 12) | 0;
    }
}

export function fromMonth(local, month) {
    switch (month) {
        case 1: {
            return local.January;
        }
        case 2: {
            return local.February;
        }
        case 3: {
            return local.March;
        }
        case 4: {
            return local.April;
        }
        case 5: {
            return local.May;
        }
        case 6: {
            return local.June;
        }
        case 7: {
            return local.July;
        }
        case 8: {
            return local.August;
        }
        case 9: {
            return local.September;
        }
        case 10: {
            return local.October;
        }
        case 11: {
            return local.November;
        }
        case 12: {
            return local.December;
        }
        default: {
            return toFail(printf("Not a valid month rank: %i"))(month);
        }
    }
}

export const padWith = (c) => ((arg) => padLeft(arg, 2, c));

export function takeLastChars(count, str) {
    return substring(str, max(comparePrimitives, 0, str.length - count));
}

export function localFormat(local, formatString, date) {
    return replace(formatString, "(d{1,4})|(h{1,2})|(H{1,2})|(m{1,2})|(M{1,4})|(s{1,2})|(t{1,2})|(y{1,5})|(\\\\.?)", (token) => {
        const symbol = token[0];
        if (((symbol || "").indexOf("\\") === 0) && ((symbol || "").length === 2)) {
            return substring(symbol || "", 1);
        }
        else {
            const matchValue = symbol || "";
            switch (matchValue) {
                case "d": {
                    return int32ToString(day_2(date));
                }
                case "dd": {
                    return padWith("0")(int32ToString(day_2(date)));
                }
                case "ddd": {
                    return fromDayOfWeek(local.Date.AbbreviatedDays, dayOfWeek(date));
                }
                case "dddd": {
                    return fromDayOfWeek(local.Date.Days, dayOfWeek(date));
                }
                case "h": {
                    return int32ToString(mod12(hour(date)));
                }
                case "hh": {
                    return padWith("0")(int32ToString(mod12(hour(date))));
                }
                case "H": {
                    return int32ToString(hour(date));
                }
                case "HH": {
                    return padWith("0")(int32ToString(hour(date)));
                }
                case "m": {
                    return int32ToString(minute(date));
                }
                case "mm": {
                    return padWith("0")(int32ToString(minute(date)));
                }
                case "M": {
                    return int32ToString(month_2(date));
                }
                case "MM": {
                    return padWith("0")(int32ToString(month_2(date)));
                }
                case "MMM": {
                    return fromMonth(local.Date.AbbreviatedMonths, month_2(date));
                }
                case "MMMM": {
                    return fromMonth(local.Date.Months, month_2(date));
                }
                case "s": {
                    return int32ToString(second(date));
                }
                case "ss": {
                    return padWith("0")(int32ToString(second(date)));
                }
                case "t": {
                    try {
                        return (hour(date) < 12) ? substring(local.Time.AM, 0, 1) : substring(local.Time.PM, 0, 1);
                    }
                    catch (matchValue_1) {
                        return "";
                    }
                }
                case "tt": {
                    return (hour(date) < 12) ? local.Time.AM : local.Time.PM;
                }
                case "y": {
                    const year = takeLastChars(2, int32ToString(year_1(date)));
                    return (getCharAtIndex(year, 0) === "0") ? substring(year, 1) : year;
                }
                case "yy": {
                    return padWith("0")(takeLastChars(2, int32ToString(year_1(date))));
                }
                case "yyy": {
                    return padLeft(int32ToString(year_1(date)), 3, "0");
                }
                case "yyyy": {
                    return padLeft(int32ToString(year_1(date)), 4, "0");
                }
                case "yyyyy": {
                    return padLeft(int32ToString(year_1(date)), 5, "0");
                }
                default: {
                    return toFail(printf("The token %s is not implemented. Please report it"))(matchValue);
                }
            }
        }
    });
}

